import React, { useState } from "react";
import { LocationInput } from "../../components/LocationInput";
import {Box, Button, Container, CssBaseline, FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import axios from 'axios';

const Calculator = () => {
    // transportation modes
    const transportationModes = ["Car", "Plane", "Bus", "Tram", "Train", "Bike", "Foot"]

    // states
    const [selectedVehicle, setSelectedVehicle] = useState("");
    const [startingLocation, setStartingLocation] = useState({} as { lat?: number, lng?: number });
    const [destinationLocation, setDestinationLocation] = useState({} as { lat?: number, lng?: number });
    const [score, setScore] = useState(undefined as number | undefined)

    const sendRequest = async () => {
        if (!(selectedVehicle &&
            startingLocation.lat && startingLocation.lng
            && destinationLocation.lat && destinationLocation.lng)
        )
            return

        try {
            const user = localStorage.getItem("user")
            const username = user ? JSON.parse(user).email : "Default"
            const requestData = {
                username,
                mode: selectedVehicle.toUpperCase(),
                origLat: startingLocation.lat?.toString(),
                origLong: startingLocation.lng?.toString(),
                destLat: destinationLocation.lat?.toString(),
                destLong: destinationLocation.lng?.toString()
            }

            const response = await axios.post('/api/calculate', requestData)
            if (response.status < 200 || response.status >= 300) {
                console.log("Unexpected status code:", response.status)
            }

            const score = response.data
            if (!Number.isFinite(score)) {
                console.log('did not receive number:', score)
                return
            }
            console.log(`received score ${score}`)
            setScore(score)
        } catch (error) {
            console.error('Error making API request:', error);
        }
    }

    return (
        <div>
            <Container sx={{ width: 500 }}>
                <CssBaseline />
                <Box
                    sx={{
                        mt: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <h1>Calculator</h1>
                    <Box sx={{ alignItems: "center", width: 400 }}>
                        <Grid container direction={"column"} spacing={2}>
                            <Grid item>
                                <FormControl fullWidth>
                                    <InputLabel id={"vehicle-selection-label"} size={"normal"}>Vehicle</InputLabel>
                                    <Select
                                        labelId={"vehicle-selection-label"}
                                        value={selectedVehicle}
                                        label={"Vehicle"}
                                        onChange={(event) => setSelectedVehicle(event.target.value)}
                                    >
                                        <MenuItem key={""}></MenuItem>
                                        {transportationModes.map((transportationMode) => (
                                            <MenuItem key={transportationMode} value={transportationMode}>
                                                {transportationMode}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item>
                                <LocationInput
                                    label={"Start"}
                                    onChange={(coordinates: { lat: number, lng: number }) => setStartingLocation(coordinates)}
                                />
                            </Grid>

                            <Grid item>
                                <LocationInput
                                    label={"Destination"}
                                    onChange={(coordinates: { lat: number, lng: number }) => setDestinationLocation(coordinates)}
                                />
                            </Grid>

                            <Grid item>
                                <Button
                                    variant = {"contained"}
                                    fullWidth
                                    onClick={() => sendRequest()}
                                    disabled = {!(selectedVehicle &&
                                        startingLocation.lat && startingLocation.lng
                                        && destinationLocation.lat && destinationLocation.lng)}
                                >
                                    Calculate CO2!
                                </Button>
                            </Grid>

                            {score !== undefined &&
                                <Grid item container justifyContent="center" alignItems="center">
                                    <span style={{ fontSize: '1.5em' }}>
                                        You saved <span style={{color: 'forestgreen', fontWeight: 'bold'}}>{score} grams</span> of CO2!
                                    </span>
                                </Grid>
                            }
                        </Grid>
                    </Box>

                    {/*{selectedVehicle &&*/}
                    {/*    startingLocation.lat && startingLocation.lng*/}
                    {/*    && destinationLocation.lat && destinationLocation.lng*/}
                    {/*    && (*/}
                    {/*        <div>*/}
                    {/*            <p>Selected Vehicle: {selectedVehicle}</p>*/}
                    {/*            <p>Start Coordinates: {startingLocation.lat} {startingLocation.lng}</p>*/}
                    {/*            <p>Destination Coordinates: {destinationLocation.lat} {destinationLocation.lng}</p>*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                </Box>
            </Container>
        </div>
    );
}

export default Calculator;