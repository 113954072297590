import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

interface LeaderboardData {
    name: string, totalCo2: number
}

const Leaderboard = () => {
    const [data, setData] = useState<LeaderboardData[]>([])

    useEffect(() => {
        axios.get<LeaderboardData[]>('/api/leaderboard')
            .then((response) => setData(response.data))
    }, [])

    return(
        <Box margin="30px">
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="Leaderboard Table">
                <TableHead>
                    <TableRow>
                    <TableCell>Position</TableCell>
                    <TableCell align="right">Name</TableCell>
                    <TableCell align="right">Total CO2 saved</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => (
                    <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                        #{index + 1}
                        </TableCell>
                        <TableCell align="right">{row.name}</TableCell>
                        <TableCell align="right">{row.totalCo2}g</TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default Leaderboard;