import React from "react";
//custom imports
import { LockOutlined } from "@mui/icons-material";
import {
    Container,
    CssBaseline,
    Box,
    Typography,
    TextField,
    Button,
  } from "@mui/material";
  import { useState } from "react";
  import loginTreeImage from './media/login_tree.png';
import User from "./main/controller/user";

const Login = () => {
    //login states
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    var errorText = 'Nothing here';
    
    //login handler
    const handleLogin = () => {
      const user = new User('', '', password, email, 'login');
      localStorage.setItem("user", JSON.stringify(user))
      window.location.href = './main/Dashboard';
    }; 


    return (
    <>
      <div style={{ backgroundColor: "#ffffff", minHeight: "100vh" }}>
      <Container maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            mt: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
           <img
              src={loginTreeImage}
              alt="Image"
              style={{ width: '150px', height: '150px', marginRight: '10px', marginBottom: '0px', marginTop: '20vh' }}
            />

           <Box sx={{ mt: '0%', width: '100%' }}>
           <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />

            <Box sx={{ mt: '4%', width: '100%', height: '100px',borderRadius: '5px', backgroundColor: '#f44336', padding: '15px', color: 'white' }}>
                <Typography component="div" fontWeight="" color="white">
                {errorText}
                </Typography>
            </Box>

            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 2, backgroundColor: '#31251c', color: 'white' }}
              onClick={handleLogin}
            >
            <Typography component="div" fontWeight="bold">
              Login
              </Typography>
            </Button>
          </Box>
        </Box>
      </Container>
      </div>
    </>
  );
}

export default Login;