import React from "react";
import { FormControl, TextField } from "@mui/material";
import { usePlacesWidget } from "react-google-autocomplete";

interface LocationInputProps {
    label: string;
    onChange: (coordinates: { lat: number; lng: number; }) => void;
}

export const LocationInput: React.FC<LocationInputProps> = ({ label, onChange }) => {
    const { ref: mapsRef } = usePlacesWidget({
        apiKey: "AIzaSyCk8ekoZcl-6SYHlRNHM8UgY5g5nahEXA0",
        onPlaceSelected: (place) => {
            console.log(place)
            const { location: {lat: latFn, lng: lngFn} } = place?.geometry || {};

            if (latFn && lngFn) {
                const lat = latFn();
                const lng = lngFn();

                console.log(`Latitude: ${lat}, Longitude: ${lng}`);
                onChange({lat, lng})
            }
        },
        options: {
            fields: ["geometry", "formatted_address"],
            types: ["address"] // ["train_station", "transit_station", "subway_station", "bus_station", "light_rail_station"]
        }
    })

    return (
        <div>
            <FormControl fullWidth>
                <TextField type={"text"} label={label} inputRef={mapsRef}/>
            </FormControl>
        </div>
    );
}