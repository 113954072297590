import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Routes, useLocation} from 'react-router-dom';
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/main/Dashboard";
import Leaderboard from "./pages/main/Leaderboard";
import UserProfile from "./pages/main/UserProfile";
import Vehicle from "./pages/main/Vehicle";
import Calculator from "./pages/main/Calculator";
import LoginRegisterSelect from "./pages/LoginRegisterSelect";
import BottomNavigationWrapper from "./pages/BottomNavigationWrapper";
import axios from 'axios';



function App() {
  if (process.env.NODE_ENV === "development") {
    axios.defaults.baseURL = "http://localhost:8080"
  }

  const location = useLocation();
  const routesToHide = ['/Login', '/Register', '/LoginRegisterSelect'];
  const hideNavigation = routesToHide.includes(location.pathname);
  return (

    <>
    <div className="app-container">
      <Routes>
        <Route path="/" element = {<Home/>} />
        <Route path="/login" element = {<Login/>} />
        <Route path="/Register" element = {<Register/>} />
        <Route path="/main/Calculator" element = {<Calculator/>} />
        <Route path="/main/Dashboard" element = {<Dashboard/>} />
        <Route path="/main/Leaderboard" element = {<Leaderboard/>} />
        <Route path="/main/UserProfile" element = {<UserProfile/>} />
        <Route path="/main/Vehicle" element = {<Vehicle/>} />
        <Route path="/LoginRegisterSelect" element = {<LoginRegisterSelect/>} />
      </Routes>
      
       {/* Bottom Navigation */}
       {!hideNavigation && (
          <div className="bottom-navigation-container">
            <BottomNavigationWrapper />
          </div>
        )}
      </div>
    </>

  );
}

export default App;
