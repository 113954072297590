import React from "react";
import {
    Box,
  } from "@mui/material";
import TripStatistics from "./widgets/tripStatistics";
import ChallangeStatistics from "./widgets/challangeStatistics";
import SomeStatistic from "./widgets/someStatistic";
import { ArrowBack, Notifications, NotificationsActive, Settings } from "@mui/icons-material";

const Dashboard = () => {
    return(
        <Box
        sx={{
          mt: '0vh',
          marginTop: '5vh',
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: '100%',
          width: '100%',
          position: 'relative',
        }}>


        <Box
        sx={{
          mt: '0vh',
          display: "flex",
          flexDirection: "row",
          alignItems: "top",
          maxWidth: '100vh',
          height: '7%',
          width: '90%',
          position: 'absolute',
          marginTop: '-4vh' 

        }}>


          {<Notifications style={{ fontSize: 34, color: '#067477', marginLeft: '80%'}} />}
          {<Settings style={{ fontSize: 34, color: '#067477', marginLeft: '0%'}} />}
     
          </Box>



          <Box
          sx={{
            mt: '0vh',
            marginTop: '2vh',
            width: "100%",
            maxWidth: '100vh',
            height: "100%",
          }}>
            <div>
              <TripStatistics />
            </div>
          </Box>


          <Box
          sx={{
            mt: '0',
            marginTop: '2vh',
            width: "100%",
            maxWidth: '100vh',
          }}>
            <div>
              <ChallangeStatistics />
            </div>
            
          </Box>

          <Box
          sx={{
            mt: '0',
            marginTop: '2vh',
            width: "100%",
            maxWidth: '100vh',
          }}>
            <div>
              <SomeStatistic />
            </div>
            
          </Box>
        </Box>
    )
}

export default Dashboard;