import React from 'react';
import { Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';


const barGraph = () => {

    
    const chartData = {
        labels: ['Co2', 'km', 'score'],
        datasets: [
          {
            label: 'Some Data',
            backgroundColor: '#08A4A7',
            data: [75, 90, 45],
          },
        ],
      };
      
    return(  
    <Box
        sx={{
          width: '100%',
          marginTop: '0px', 
        }}>

        <Bar
          data={chartData}
          options={{
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins:{
              legend:{
                display: false,
              },
            },
          }}
        />
      </Box>);
}


export default barGraph;