import React from 'react';
import { CircularProgress, Typography } from '@mui/material';

interface CircularLoadingBarProps {
  progress: number;
  text?: string;
}

const CircularLoadingBar: React.FC<CircularLoadingBarProps> = ({ progress, text }) => {

  
  return (
    <div
      style={{
        margin: '3%',
        position: 'relative',
        color: '#ffffff',
      }}
    >
      <CircularProgress
        variant="determinate"
        value={progress}
        size={40}
        thickness={8}
        sx={{ color: '#08A4A7' }}
      />
      <Typography
        variant="body1"
        sx={{
          fontSize: '8px',
          color: '#000000',
          position: 'absolute',
          top: '50%',
          left: '50%',
          fontWeight: 'bold',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {progress}%
      </Typography>
      {text && (
        <Typography
          variant="body1"
          sx={{
            fontSize: '10px',
            color: '#000000',
            position: 'absolute',
            top: '90%',
            left: '20%',
            fontWeight: 'bold',
          }}
        >
          {text}
        </Typography>
      )}
    </div>
  );
};

export default CircularLoadingBar;
