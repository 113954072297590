import React from "react";
import { Avatar, Box, Typography } from '@mui/material';
import { ArrowBack, Person, Person2Outlined, Person2Sharp, PointOfSale, Score, Scoreboard, SportsScore } from "@mui/icons-material";
import ProfileBackground from '../media/wave.png';
import { relative } from "path";
import User from "./controller/user";

const UserProfile = () => {
  const user = localStorage.getItem("user")
  const parsedUser = user ? JSON.parse(user) : new User("Default", "Default", "", "default@default", "login")

    return(
        <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        
      }}
    >



         <Box
                sx={{
                    width: '100%',
                    height: '40vh',
                    overflow: 'hidden',
                    position: 'relative',
                }}
            >
                <img
                    src={ProfileBackground}
                    alt="Background"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
            </Box>

    
    <Box
    sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        position: 'absolute',
      }}>

      <Avatar
        sx={{
          width: '20vh',
          height: '20vh',
          marginTop: '5vh',
         
        }}
        alt="User ProfilePicture"
        src="/path/to/your/profile-image.jpg"
      />
    
        
        <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '16px',
        }}
        >
        <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            
        }}
        >
            {<Person style={{ fontSize: 24, marginRight: '1%' }} />}

            <Typography fontSize={'3vh'}>55</Typography>
        </Box>
        <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }}
        >
            {<SportsScore style={{ fontSize: 24, marginRight: '1%' }} />}
            <Typography fontSize={'3vh'}>20</Typography>
        </Box>            
        
            
        </Box>
        <Typography fontSize={'4vh'} fontWeight={'bold'}>{parsedUser.email}</Typography>

        </Box>

     
        <Box
      sx={{
        mt: 0,
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        height: '5vh',
        width: '85%',
        
        borderRadius: 2,
        margin: 'auto',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      }}
    >
        
    </Box>

    <Box
      sx={{
        mt: 0,
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        height: '5vh',
        width: '85%',
        
        borderRadius: 2,
        margin: 'auto',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      }}
    >
        
    </Box>

    <Box
      sx={{
        mt: 0,
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        height: '5vh',
        width: '85%',
        
        borderRadius: 2,
        margin: 'auto',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      }}
    >
        
    </Box>

    </Box>
    )
}

export default UserProfile;