import React from 'react';
import { Box, Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import CircularLoadingBar from "./circularLoadingBar";
import BarGraph from "./barGraph";
import 'chart.js/auto';


const TripStatistics = () => {
  
  const handleOnClick = () => {
    window.location.href = './Calculator';
  };

  return (
    <Box
      sx={{
        mt: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        height: '28vh',
        width: '70%',
        borderRadius: 2,
        margin: 'auto',
        paddingRight: '15%',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
      }}
    >
      <div style={{ display: 'flex', gap: '5vh', marginLeft: '-15%' }}>
        <CircularLoadingBar progress={75} text="Co2"/>
        <CircularLoadingBar progress={90} text="km"/>
        <CircularLoadingBar progress={45} text="score"/>
      </div>

      <Button
        variant="contained"
        sx={{
          position: 'absolute',
          top: '0',
          right: '0%',
          height: '100%',
          backgroundColor: '#08A4A7',
          borderTopLeftRadius: 2,
          borderBottomLeftRadius: 2,
          
        }}
        startIcon={<Add style={{ fontSize: 34, marginLeft: '15%' }} />}
        onClick={handleOnClick}
      />


        
    <Box
        sx={{
          mt: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '40vh',
          marginTop: '5%',
          marginRight: '0%',
          borderRadius: 4,
        }}
      >
        <div style={{ width: '80%' }}>
          <BarGraph />
        </div>
      </Box>


    </Box>

    
  );
};

export default TripStatistics;
