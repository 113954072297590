import { Box } from "@mui/material";
import React from "react";
import CarImage from '../media/car.png';
import ChallangeStatistics from "./widgets/challangeStatistics";
import SomeStatistic from "./widgets/someStatistic";
import { ArrowBack, ArrowBackIos, ArrowForwardIos, Group } from '@mui/icons-material';

const Vehicle = () => {
    return(

        <Box
        sx={{
            mt: '0vh',
            marginTop: '1vh',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: '100%',
            width: '100%',
          }}>

            <Box
        sx={{
            mt: '0vh',
            marginTop: '1vh',
            display: "flex",
            flexDirection: "Row",
            alignItems: "center",
            height: '100%',
            width: '100%',
            maxWidth: '50vh',
          }}>

            <Box
                sx={{
                    mt: '0vh',
                    marginTop: '0vh',
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: '30%',
                }}>
                    {<ArrowBackIos style={{ fontSize: 34, marginLeft: '15%', color: '#08A4A7'}} />}

                </Box>


                <Box
            sx={{
                mt: '0vh',
                marginTop: '0vh',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: '100%',
            }}>
                <img src={CarImage} alt="Your Alt Text" style={{ width: '100%' }} />

                </Box>

                <Box
                sx={{
                    mt: '0vh',
                    marginTop: '0vh',
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: '30%',
                }}>
                    {<ArrowForwardIos style={{ fontSize: 34, marginLeft: '15%', color: '#08A4A7'}} />}

                </Box>

            </Box>

            <Box
          sx={{
            mt: '0',
            marginTop: '2vh',
            width: "90%",
            maxWidth: '100vh',
          }}>
            <div>
              <ChallangeStatistics />
            </div>
            
          </Box>

          <Box
          sx={{
            mt: '0',
            marginTop: '2vh',
            width: "90%",
            maxWidth: '100vh',
          }}>
            <div>
              <SomeStatistic />
            </div>
            
          </Box>
        </Box>
    )
}

export default Vehicle;