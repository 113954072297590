import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Link, Outlet } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupWorkIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

const BottomNavigationWrapper = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  

  return (
    <>
      <Outlet />
      <BottomNavigation 
            value={value} 
            onChange={handleChange} 
            showLabels 
            sx={{
                backgroundColor: '#08A4A7',
                '& .MuiBottomNavigationAction-label': {
                  fontSize: '12px',
                  transition: 'font-size 0.3s',
                },
                '& .MuiSvgIcon-root': {
                  fontSize: '23px',
                  transition: 'font-size 0.3s',
                },
              }}>
        <BottomNavigationAction 
            label="Dashboard" 
            style={{ color: 'white' }}
            icon={<DashboardIcon style={{ color: 'white' }} />} 
            component={Link} 
            to="/main/Dashboard" />
        <BottomNavigationAction 
            label="Vehicle" 
            style={{ color: 'white' }}
            icon={<DirectionsCarIcon style={{ color: 'white' }} />} 
            component={Link} 
            to="/main/Vehicle" />
        <BottomNavigationAction 
            label="Leaderboard" 
            style={{ color: 'white' }}
            icon={<GroupWorkIcon style={{ color: 'white' }} />}
            component={Link} 
            to="/main/Leaderboard" />
        <BottomNavigationAction 
            label="Profile" 
            style={{ color: 'white' }}
            icon={<PersonIcon style={{ color: 'white' }} />}
            component={Link} 
            to="/main/UserProfile" />
      </BottomNavigation>
    </>
  );
};

export default BottomNavigationWrapper;
