import React from 'react';
import { Box, Button } from '@mui/material';
import LineGraph from "./lineGraph";
import 'chart.js/auto';


const challangeStatistics = () => {


  return (
    <Box
      sx={{
        mt: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        height: '20vh',
        width: '85%',
        borderRadius: 2,
        margin: 'auto',
        
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
      }}>

<Box
        sx={{
          mt: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '40vh',
        
          margin: 'auto',
          borderRadius: 4,
        }}
      >
        <div style={{ width: '80%' }}>
          <LineGraph />
        </div>
      </Box>


    </Box>

    
  );
};

export default challangeStatistics;
