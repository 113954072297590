import React from "react";
//custom imports
import { LockOutlined } from "@mui/icons-material";
import {
    Container,
    CssBaseline,
    Box,
    Avatar,
    Typography,
    TextField,
    Button,
    Grid,
  } from "@mui/material";
  import { useState } from "react";
  import { Link } from "react-router-dom";
  import loginTreeImage from './media/login_tree.png';

const LoginRegisterSelect = () => {
    //login states
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    
    //login handler
    const handleLogin = () => {
        window.location.href = './Login';
      };

    const handlRegister = () => {
        window.location.href = './Register';
      };

    return (
    <>
        <div style={{ backgroundColor: "#ffffff", minHeight: "100vh" }}>
      <Container maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            mt: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
           <img
              src={loginTreeImage}
              alt="Image"
              style={{ width: '150px', height: '150px', marginRight: '10px', marginBottom: '0px', marginTop: '20vh' }}
            />
           <Typography
            component="div"
            variant="h5"
            fontStyle="italic"
            fontWeight="bold"
            style={{ color: '#31251c', fontFamily: 'cursive' }}
            >
            Grüner fahren, CO2 sparen
            </Typography>

           <Box sx={{ mt: '10%', width: '100%' }}>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: '0%', mb: '1%', backgroundColor: '#31251c', color: 'white', width: '100%' }}
              onClick={handleLogin}
            >
              <Typography component="div" fontWeight="bold">
              Login
            </Typography>
            </Button>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 2, backgroundColor: '#31251c', color: 'white' }}
              onClick={handlRegister}
            >
            <Typography component="div" fontWeight="bold">
              Register
              </Typography>
            </Button>
          </Box>
        </Box>
      </Container>
      </div>
    </>
  );
}

export default LoginRegisterSelect;