import React from 'react';
import { Box } from '@mui/material';
import { Scatter } from 'react-chartjs-2';
import 'chart.js/auto';

const scatterPlot = () => {
  const chartData = {
    datasets: [
      {
        label: 'Some Data',
        borderColor: '#08A4A7',
        backgroundColor: '#08A4A7',
        pointBackgroundColor: '#ffffff',
        pointBorderWidth: 2,
        pointRadius: 6,
        data: [
          { x: 'Co2', y: 75 },
          { x: 'km', y: 90 },
          { x: 'score', y: 45 },
        ],
      },
    ],
  };

  return (
    <Box
      sx={{
        width: '100%',
        marginTop: '0px',
      }}
    >
      <Scatter
        data={chartData}
        options={{
          scales: {
            x: {
              type: 'category',
            },
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </Box>
  );
};

export default scatterPlot;
