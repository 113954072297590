import React from "react";
import {
    Container,
    CssBaseline,
    Box,
    Typography,
    TextField,
    Button,
    Link
  } from "@mui/material";
  import { useState } from "react";
  import loginTreeImage from './media/login_tree.png';
  import User from './main/controller/user';

const Register = () => {
    //login states

    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [passwordOne, setPasswordOne] = useState("");
    const [passwordTwo, setPasswordTwo] = useState("");
    var message = 'Nothing here';
    
    //login handler
    const handleRegister = () => {
      const user = new User(name, username, email, passwordOne, 'register');
      window.location.href = './Login';
    };


    return (
    <>
        <div style={{ backgroundColor: "#ffffff", minHeight: "100vh" }}>
      <Container maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            mt: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
           <img
              src={loginTreeImage}
              alt="Image"
              style={{ width: '150px', height: '150px', marginRight: '10px', marginBottom: '0px', marginTop: '1vh' }}
            />

           <Box sx={{ mt: '0%', width: '100%' }}>
           <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Your Name"
              name="name"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Choose a Username"
              name="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
           <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              value={passwordOne}
              onChange={(e) => {
                setPasswordOne(e.target.value);
              }}
            />

        <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="Repeat password"
              type="password"
              value={passwordTwo}
              onChange={(e) => {
                setPasswordTwo(e.target.value);
              }}
            />

            <Box sx={{ mt: '4%', width: '100%', height: '100px',borderRadius: '5px', backgroundColor: '#f44336', padding: '15px', color: 'white' }}>
                <Typography component="div" fontWeight="" color="white">
                {message}
                </Typography>
            </Box>


            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 2, backgroundColor: '#31251c', color: 'white' }}
              onClick={handleRegister}>

            <Typography component="div" fontWeight="bold">
              Register
              </Typography>

              
            </Button>
            <Link href="/forgot-password" color="inherit">
                Forgot password?
            </Link>
          </Box>
        </Box>
      </Container>
      </div>
    </>
  );
}

export default Register;