import React from 'react';
import { Box } from '@mui/material';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const lineGraph = () => {
  const chartData = {
    labels: ['Co2', 'km', 'score'],
    datasets: [
      {
        label: 'Some Data',
        borderColor: '#08A4A7',
        borderWidth: 2,
        pointBackgroundColor: '#08A4A7',
        data: [75, 90, 45],
      },
    ],
  };

  return (
    <Box
      sx={{
        width: '100%',
        marginTop: '0px',
      }}
    >
      <Line
        data={chartData}
        options={{
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </Box>
  );
};

export default lineGraph;
