class User {
    // Relevant user data
    private id?: string;
    private name: string;
    private username: string;
    private password: string;
    private email: string;
    private operationType: 'register' | 'login';
  
    constructor(name: string, username: string, password: string, email: string, operationType: 'register' | 'login') {
      this.name = name;
      this.username = username;
      this.password = password;
      this.email = email;
      this.operationType = operationType;
    }

  
    // Create JSON for Register, no user id will be set here
    static jsonSerialize(user: User): string {
      return JSON.stringify({
        name: user.getName(),
        username: user.getUsername(),
        password: user.getPassword(),
        email: user.getEmail(),
        operationType: user.getOperationType(),
      });
    }

  
    // Getter methods
    getName(): string {
      return this.name;
    }
  
    getUsername(): string {
      return this.username;
    }
  
    getPassword(): string {
      return this.password;
    }
  
    getEmail(): string {
      return this.email;
    }
  
    getOperationType(): 'register' | 'login' {
      return this.operationType;
    }
  
    // Setter methods
    setName(name: string): void {
      this.name = name;
    }
  
    setUsername(username: string): void {
      this.username = username;
    }
  
    setPassword(password: string): void {
      this.password = password;
    }
  
    setEmail(email: string): void {
      this.email = email;
    }
  
    setOperationType(operationType: 'register' | 'login'): void {
      this.operationType = operationType;
    }
  
    updateUserInfo(name: string, username: string, password: string, email: string): void {
      this.name = name;
      this.username = username;
      this.password = password;
      this.email = email;
    }
  }
  
  export default User;
  